// @ts-nocheck
/* eslint-disable */

import {routes} from '@/_core/routes';
import {flowRoute, sessionConfig} from '@/_core/util/util';
import {SOFTFACT_SERVICES} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {surveyFlows} from '@modules/FlowEngine/config/surveyFlows';
import {useLocationEffect} from '@modules/Navigation/hooks/locationEffect';
import {_Location} from '@modules/Navigation/types/location.model';
import {appState, dispatchAction} from '@modules/State/util/util';
import {submitProfileTrackingEventAction} from '@modules/Tracking/util/trackingUtil';
import {updateProfile} from '../state/profileSlice';
import {isLoggedIn} from '../util/authUtil';
import {surveyFlowRoute} from '../util/profileUtil';

/**
 * Here we can add effects that should be triggered when the location changes
 * This serves as a wrapper for all our location effects to be able to find them in one place
 */
export function useProfileLocationEffects(): void {
  useProfileTrackingLocationEffects();
}

function useProfileTrackingLocationEffects(): void {
  const softfactPatterns = SOFTFACT_SERVICES.map(service => surveyFlowRoute(service)).filter(Boolean);
  const exercisesPatterns = [flowRoute('ProfileExercises')];
  /*
  Regex INEED TO MATCH:
  1. softfact flows: [
          SOFTFACT_SERVICES.map(service => `/flow/${surveyFlows[service]?.name}/.*`),
          '/softfacts/flow/.*',
        ]
    2. exercises flow: ['/flow/profile-exercises/.*', '/exercises/flow/.*']
   */

  const createTrackingEvent = async (
    category: string,
    type: string,
    action: string,
    metadata: Record<string, any>,
    source: string
  ): Promise<void> => {
    await submitProfileTrackingEventAction({
      category,
      source,
      type,
      action,
      metadata,
      // For now, we don't save this to avoid having so many db entries
      withSave: false,
      withEvent: true,
    });

    // Update latestSoftfact in app state
    const profile = appState().profile?.currentProfile;
    if (profile) {
      await dispatchAction(
        updateProfile({
          ...profile,
          latestSoftfact: metadata.service,
        })
      );
    }
  };
  const testLocation = (patterns: string[], location: _Location): boolean =>
    patterns.some((pattern: string) => new RegExp(pattern).test(location.pathname));

  useLocationEffect(location => {
    if (!isLoggedIn()) {
      return;
    }

    // Check if the current location matches any of the softfact flows
    if (testLocation(softfactPatterns as string[], location)) {
      const serviceMatch = location.pathname.match(/\/flow\/([^/]+).*/);
      let serviceName = serviceMatch ? serviceMatch[1] : null; // Extract service name from URL

      // match service name from surveyFlows
      serviceName = Object.keys(surveyFlows).find(key => surveyFlows[key].name === serviceName) ?? serviceName;
      if (!serviceName || serviceName === 'softfacts') {
        // skip tracking
        return;
      }

      void createTrackingEvent('service', 'internal', 'view', {service: serviceName}, location.pathname);
    }

    // Check if the current location matches any of the exercises flows
    if (testLocation(exercisesPatterns, location)) {
      const urlParams = new URLSearchParams(location.search);
      const serviceName = urlParams.get('service') ?? null; // Extract the 'service' query parameter

      if (!serviceName) return;
      void createTrackingEvent('service', 'internal', 'view', {service: serviceName}, location.pathname);
    }
  });
}
