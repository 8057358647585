// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SideBarEntries} from '@/_core/sideBar/SideBarEntries';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconOrganisation, IconSettings} from '@modules/Core/components/base/Icons/Icons';
import {_NewSideBarRoute} from '@modules/Core/types/routesTypes';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {useInstitution} from '@modules/Institution/hooks/Institution';
import {useProfile} from '@modules/Profile/hooks/profile';
import {trans} from '@modules/Translations/util/i18n';
import {navigateToFlow} from '../util/util';

interface _SideBarOrganization {
  data: _NewSideBarRoute;
}

export const SideBarOrganization: React.FC<_SideBarOrganization> = ({data}) => {
  const {currentProfile} = useProfile();
  const {currentInstitution} = useInstitution();

  if (!currentProfile || !currentInstitution) {
    console.error('No user found');
    return null;
  }

  return (
    <div className="flex items-start gap-1 flex-col w-full mb-1">
      <div className="flex pl-2 pr-1 w-full h-5 items-center justify-between">
        <AvatarUser label={trans('base.organisation_menu_title')} icon={IconOrganisation} lineClamp bold />
        {can('organisation.admin') && (
          <IconButton
            icon={IconSettings}
            onClick={() => navigateToFlow('OrganisationMembers')}
            label={trans('base.settings_incl_members')}
            labelPlc="top-left"
          />
        )}
      </div>
      <SideBarEntries data={data} />
    </div>
  );
};
