// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SideBarEntries} from '@/_core/sideBar/SideBarEntries';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconOrganisation, IconSettings, IconTeam} from '@modules/Core/components/base/Icons/Icons';
import {_NewSideBarRoute} from '@modules/Core/types/routesTypes';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {useInstitution} from '@modules/Institution/hooks/Institution';
import {useProfile} from '@modules/Profile/hooks/profile';
import {useTeam} from '@modules/Team/hooks/team';
import {useTeams} from '@modules/Team/hooks/teams';
import {trans} from '@modules/Translations/util/i18n';
import {navigateToFlow} from '../util/util';

interface _SideBarTeams {
  data: _NewSideBarRoute;
}

export const SideBarTeams: React.FC<_SideBarTeams> = ({data}) => {
  const {currentProfile} = useProfile();
  const {currentInstitution} = useInstitution();
  const [currentTeam] = useTeam();

  if (!currentProfile || !currentInstitution) {
    console.error('No user found');
    return null;
  }

  return (
    <div className="flex items-start gap-1 flex-col w-full mb-1">
      <div className="flex pl-2 pr-1 w-full h-5 items-center justify-between">
        <AvatarUser label={trans('organisation.team')} icon={IconTeam} lineClamp bold />
        {currentTeam && can('team.admin') && (
          <IconButton
            icon={IconSettings}
            onClick={() => navigateToFlow('TeamMembers', {teamId: currentTeam.id})}
            label={trans('base.settings_incl_members')}
            labelPlc="top-left"
          />
        )}
      </div>

      <SideBarEntries data={data} />
    </div>
  );
};
