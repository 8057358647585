// @ts-nocheck
/* eslint-disable */

import {flowRoute} from '@/_core/util/util';
import {
  IconAdd,
  IconCreditCard,
  IconCycle,
  IconEmail,
  IconFactionary,
  IconFlow,
  IconHome,
  IconLogout,
  IconNote,
  IconOrganisation,
  IconProfile,
  IconProfilePrivacy,
  IconSettings,
  IconShare,
  IconSmile,
  IconSocialRole,
  IconSurvey,
  IconTaskCompleter,
  IconTeam,
  IconTeamAnalysis,
  IconTeamDevelopment,
} from '@modules/Core/components/base/Icons/Icons';
import {SOFTFACT_SERVICES} from '@modules/Core/config/services';
import {can, isImpersonating} from '@modules/Core/util/rolesAndPermissionsUtil';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {_Institution} from '@modules/Institution/types/institution.model';
import {switchInstitutionFlow} from '@modules/Institution/util/institutionUtil';
import {surveyFlowRoute} from '@modules/Profile/util/profileUtil';

const loggingRoutes = {
  logout: {
    type: 'general',
    to: flowRoute('Logout') || '',
    value: 'logout',
    pattern: '/logout',
    displayValue: 'base.logout',
    icon: IconLogout,
  },
};

const userMenuRoutes = {
  profile: {
    type: 'profile',
    to: flowRoute(FLOWS.ProfileInfo),
    value: 'profile',
    pattern: flowRoute(FLOWS.ProfileInfo),
    displayValue: 'base.profile',
    icon: IconProfile,
  },
  membership: {
    type: 'profile',
    to: flowRoute(FLOWS.Payment),
    value: 'membership',
    pattern: flowRoute(FLOWS.Payment),
    displayValue: 'profile.subscriptions.displayValue',
    icon: IconCreditCard,
  },
  organisation_switch: {
    type: 'organisation',
    onClick: switchInstitutionFlow,
    value: 'organisation.switch',
    pattern: '/settings/organisation',
    displayValue: 'institution.switch.settings_entry',
    icon: IconCycle,
  },
  settings: {
    type: 'profile',
    to: flowRoute(FLOWS.ProfileSettings),
    value: 'settings',
    pattern: flowRoute(FLOWS.ProfileSettings),
    displayValue: 'base.settings',
    icon: IconSettings,
  },
  emails: {
    type: 'profile',
    to: flowRoute(FLOWS.Emails),
    value: 'emails.scheduling',
    pattern: flowRoute(FLOWS.Emails),
    displayValue: 'emails.title',
    icon: IconEmail,
  },
  softfactionary: {
    type: 'general',
    to: flowRoute(FLOWS.Softfactionary),
    value: 'softfactionary',
    pattern: flowRoute(FLOWS.Softfactionary),
    displayValue: 'softfactionary.title',
    icon: IconFactionary,
  },
};

const designSystemRoutes = Object.fromEntries(
  [
    'alerts',
    'avatars',
    'buttons',
    'cards',
    'colors',
    'elements',
    'icons',
    'inputs',
    'selects',
    'sliders',
    'tabs',
    'toolTips',
    'typography',
    'tutorial',
  ].map(item => [
    item,
    {
      type: 'general',
      to: `/design-system/${item}`,
      value: item.charAt(0).toUpperCase() + item.slice(1),
      pattern: `/design-system/${item}`,
      displayValue: item,
      icon: IconSocialRole,
    },
  ])
);

export const SideBarRoutes = {
  profile: {
    items: [
      {
        type: 'profile',
        flowKey: 'ProfileDashboard',
        value: 'dashboard',
        displayValue: 'base.dashboard_menu_title',
        icon: IconHome,
      },
      {
        type: 'profile',
        flowKey: 'Softfacts',
        value: 'softfacts',
        displayValue: 'base.soft_facts_menu_title',
        pattern: [
          flowRoute(FLOWS.Softfacts),
          ...SOFTFACT_SERVICES.map(service => surveyFlowRoute(service)).filter(Boolean),
        ],
        icon: IconSmile,
      },
      {
        type: 'profile',
        flowKey: 'ProfileExercises',
        value: 'profile-exercises',
        displayValue: 'base.exercises_menu_title',
        icon: IconTaskCompleter,
      },
      {
        type: 'profile',
        flowKey: 'ProfileJournals',
        value: 'journal',
        displayValue: 'base.journal',
        icon: IconNote,
      },
      {
        type: 'profile',
        flowKey: 'ThirdPersonFeedback',
        value: '360-feedback',
        displayValue: 'base.third_person_feedback_menu_title',
        icon: IconCycle,
        shouldDisplay: () => !isImpersonating(),
      },
      {
        type: 'profile',
        flowKey: 'ProfileSharedResults',
        value: 'shared-results',
        displayValue: 'base.shared_results',
        icon: IconShare,
        shouldDisplay: () => !isImpersonating(),
      },
      {
        type: 'profile',
        flowKey: 'CustomSurvey',
        value: 'surveys.custom',
        displayValue: 'surveys.surveys',
        featureFlag: 'custom_surveys',
        icon: IconSurvey,
        shouldDisplay: ({institution}: {institution: _Institution}) =>
          !isImpersonating() && can('organisation.surveys:manage', {institution_id: institution?.id}),
      },
    ],
  },
  team: {
    items: [
      {
        type: 'team',
        flowKey: 'TeamDashboard',
        value: 'team.dashboard',
        displayValue: 'base.dashboard_menu_title',
        icon: IconHome,
      },
      {
        type: 'team',
        flowKey: 'TeamAnalysis',
        value: 'team.analysis',
        displayValue: 'service.team_reflection',
        icon: IconTeamAnalysis,
      },
      {
        type: 'team',
        flowKey: 'TeamDevelopment',
        value: 'team.development',
        displayValue: 'service.team_development_menu',
        icon: IconTeamDevelopment,
      },
    ],
  },
  organisation: {
    items: [
      {
        type: 'organisation',
        flowKey: 'OrganisationDashboard',
        value: 'organisation.dashboard',
        displayValue: 'base.dashboard',
        icon: IconHome,
      },
      {
        type: 'organisation',
        flowKey: 'OrganisationStructure',
        value: 'organisation.structure',
        displayValue: 'base.institutional_hierarchy',
        icon: IconOrganisation,
        shouldDisplay: () => can('organisation.overview:view'),
      },
      {
        type: 'organisation',
        flowKey: 'FeedbackAnalysis',
        value: 'feedback-analysis',
        displayValue: 'feedback_analysis.title_planner',
        featureFlag: 'feedback_analysis',
        icon: IconCycle,
      },
      {
        type: 'organisation',
        flowKey: 'CandidateAnalysis',
        value: 'candidate-analysis',
        displayValue: 'candidate_analysis.title',
        featureFlag: 'candidate_analysis',
        icon: IconTeam,
      },
    ],
  },
  general: {items: []},
};

export {loggingRoutes, userMenuRoutes, designSystemRoutes};
