// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {usePathname} from 'next/navigation';
import {SideBarRoutes} from '@/_core/routes';
import {flowRoute} from '@/_core/util/util';
import {isFeatureEnabled} from '@modules/Core/hooks/featureFlags';
import {_NewSideBarRoute, _NewSideBarRoutes, _Route, _RouteData, _NestedRoute} from '@modules/Core/types/routesTypes';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {useTeam} from '@modules/Team/hooks/team';
import {_Team} from '@modules/Team/types/team.model';
import {isActiveRoute} from './util/routesHelper';

interface PrepareItemData extends _RouteData {
  pathname: string;
}

const resolveDisplayValue = (
  value: string | ((data: _RouteData) => string) | null,
  params: _RouteData = {}
): string | null => {
  if (typeof value === 'function') return value(params);
  return value;
};

const resolveShouldDisplay = (
  condition: ((data: _RouteData) => boolean) | boolean | undefined,
  params: _RouteData = {}
): boolean => {
  if (condition === undefined) return true;
  if (typeof condition === 'function') return condition(params);
  return condition;
};

const isRoute = (item: _Route | _NestedRoute): item is _Route => {
  return 'type' in item;
};

const filterItems = (
  items: _NewSideBarRoutes | Array<_Route | _NestedRoute>,
  context: _RouteData = {}
): _NewSideBarRoutes | Array<_Route | _NestedRoute> => {
  if (!Array.isArray(items)) {
    return Object.fromEntries(
      Object.entries(items || {}).map(([key, value]) => [key, {items: filterItems(value?.items || [], context)}])
    ) as _NewSideBarRoutes;
  }

  return items
    .filter(item => {
      if (isRoute(item)) {
        return resolveShouldDisplay(item.shouldDisplay, context);
      }
      return true;
    })
    .map(item => {
      if ('items' in item && item.items?.length) {
        return {...item, items: filterItems(item.items, context) as _Route[]};
      }
      return item;
    });
};

const prepareItem = (item: _Route | _NestedRoute, data: PrepareItemData): _Route | _NestedRoute => {
  const prepared = {...item};
  if (prepared.flowKey) {
    const route = flowRoute(prepared.flowKey, prepared.type === 'team' ? data.teamId : undefined);
    if (route) {
      prepared.to = route;
      if (!prepared.pattern) {
        prepared.pattern = route;
      }
    }
  }

  if ('displayValue' in prepared) {
    prepared.displayValue = resolveDisplayValue(prepared.displayValue, {
      institution: data.institution,
      team: data.team,
      teamId: data.teamId,
    });
  }

  if ('items' in prepared && prepared.items?.length) {
    prepared.items = prepared.items.map(subItem => prepareItem(subItem, data)) as _Route[];
  }

  prepared.active = isActiveRoute(data.pathname, prepared.pattern);
  return prepared;
};

export const usePrepareSideBar = (
  availableRoutes: Record<string, unknown> = {},
  currentProfile: _Profile | null,
  currentInstitution: _Institution | null,
  teams: _Team[] = []
): _NewSideBarRoutes => {
  const [items, setItems] = useState<_NewSideBarRoutes>({
    profile: {items: []},
    team: {items: []},
    organisation: {items: []},
    general: {items: []},
  });

  const pathname = usePathname() || '';
  const [currentTeam] = useTeam();

  useEffect(() => {
    const filterAndPrepare = (section: keyof _NewSideBarRoutes): Array<_Route | _NestedRoute> =>
      (SideBarRoutes[section]?.items || [])
        .filter(
          item =>
            (!item.flowKey || availableRoutes[item.flowKey]) &&
            (!('featureFlag' in item) || !item.featureFlag || isFeatureEnabled(item.featureFlag as string)) &&
            (item.type !== 'team' || currentTeam) &&
            (item.type !== 'organisation' || can('organisation.overview:view'))
        )
        .map(item =>
          prepareItem(item as _Route | _NestedRoute, {
            pathname,
            institution: currentInstitution,
            team: currentTeam,
            teamId: currentTeam?.id,
          })
        );

    const newItems: _NewSideBarRoutes = {
      profile: {items: filterAndPrepare('profile')},
      team: {items: filterAndPrepare('team')},
      organisation: {items: filterAndPrepare('organisation')},
      general: {items: filterAndPrepare('general')},
    };

    setItems(
      filterItems(newItems, {
        institution: currentInstitution,
        team: currentTeam,
        teamId: currentTeam?.id,
        profile: currentProfile,
      }) as _NewSideBarRoutes
    );
  }, [availableRoutes, currentProfile, currentInstitution, teams, pathname, currentTeam]);

  return items;
};
