// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ActionMeta} from 'react-select';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {FlagDropDown} from '@modules/Core/components/base/FlagDropDown';
import {IconSchedule, IconUndo} from '@modules/Core/components/base/Icons/Icons';
import {Select, _SelectValue} from '@modules/Core/components/base/inputs/Select';
import {Logo} from '@modules/Core/components/base/Logo';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {can, isImpersonating} from '@modules/Core/util/rolesAndPermissionsUtil';
import {goToCoachingSession} from '@modules/Core/util/routingUtil';
import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';
import {useInstitution} from '@modules/Institution/hooks/Institution';
import {_Institution} from '@modules/Institution/types/institution.model';
import {inviteToTeamFlow} from '@modules/Institution/util/institutionDialogFlowsUtil';
import {useLocation} from '@modules/Navigation/hooks/location';
import {TopBarNotifications} from '@modules/Notifications/components/TopBarNotifications';
import UpgradePlanButton from '@modules/Profile/components/payments/UpgradePlanButton';
import {useProfile} from '@modules/Profile/hooks/profile';
import {endImpersonation} from '@modules/Profile/state/profileSlice';
import {dispatchAction} from '@modules/State/util/util';
import {useTeam} from '@modules/Team/hooks/team';
import {useTeams} from '@modules/Team/hooks/teams';
import {_Team} from '@modules/Team/types/team.model';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';
import {navigateTo, navigateToFlow, navigateToFlowUsingWindow} from '../util/util';
import {TopBarUser} from './TopBarUser';

// The TopBarBackground is a simple div that only creates the background color along the entire width of the screen.
// it can't be part of the global grid as all elements inside the global grid are limited to the max width of the global grid.

// The TopBar itself should only be as big as the grid but its background should span the entire screen width.
// Therefore, the two have to be separated. To achieve that the Topbar is above the TopBarBackground, its margin-top is set to a negative value to make it overlap the TopBarBackground.

// not part of the main grid

export const TopBarBackground: React.FC = () => (
  <div className="flex flex-shrink-0 sticky top-[-1px] z-20 w-screen bg-white500 topBar-height shadow-2" />
);

interface _TopBarProps {
  showLogo?: boolean;
}

// part of the main grid -> stuff like custom-col-width works
export const TopBar: React.FC<_TopBarProps> = ({showLogo = true}) => {
  const [showCoachingButton, setShowCoachingButton] = useState(false);
  const [coachingButtonUrl, setCoachingButtonUrl] = useState<string | undefined>(undefined);
  const {currentProfile, originalProfile} = useProfile();
  const {currentLanguage, setLanguage} = useLanguage();
  const location = useLocation();

  const [currentTeams] = useTeams();
  const [currentTeam] = useTeam();
  const {currentInstitution} = useInstitution();

  const institutionOptions =
    currentProfile?.institutions?.map((institution: _Institution) => ({
      value: institution.id,
      label: institution.name,
    })) || [];

  const teamOptions =
    currentTeams?.map((team: _Team) => ({
      value: team.id,
      label: team.name,
    })) || [];

  const handleInstitutionChange = (newValue: _SelectValue, actionMeta?: ActionMeta<_SelectValue>) => {
    if (newValue.value === 'new') {
      fireDialogFlow('NewOrganisation', null, {hideButtons: true, showCloseButton: true, size: 'lg'});
    } else if (newValue.value === currentInstitution?.id) {
      navigateToFlow('OrganisationDashboard');
    } else {
      navigateToFlow('SwitchOrganisation', {institutionId: newValue.value as string});
    }
  };

  const handleTeamChange = (newValue: _SelectValue, actionMeta?: ActionMeta<_SelectValue>) => {
    if (newValue.value === 'new') {
      fireDialogFlow('NewTeam', null, {hideButtons: true, showCloseButton: true, size: 'lg'});
    } else if (location.pathname.match(/^\/flow\/team\/[^/]+\//)) {
      const newPath = location.pathname.replace(/^(\/flow\/team\/)[^/]+(\/.*)?$/, `$1${newValue.value}$2`);
      navigateTo(newPath);
    } else {
      navigateToFlow('TeamDashboard', {teamId: newValue.value as string});
    }
  };

  useEffect(() => {
    setShowCoachingButton(currentProfile?.showCoachingButton ?? false);
    setCoachingButtonUrl(currentProfile?.coachingButtonUrl ?? undefined);
  }, [currentProfile]);

  const handleEndImpersonation = async () => {
    dispatchReactEvent('navigate.start', {step: 'endImpersonation'});
    await dispatchAction(endImpersonation());
    navigateToFlowUsingWindow('ProfileSharedResults');
  };

  return (
    <div className="sticky top-[-1px] z-20">
      <div className="w-full flex items-start justify-between topBarContainer z-50 topBar-cont">
        <div className="h-8 flex items-center justify-start w-full gap-4">
          {showLogo && <Logo href="/" />}
          <div className="flex gap-4">
            {currentProfile && isImpersonating() && (
              <div className="text-prussian300 flex items-center gap-0">
                {trans('base.impersonating_as')} {currentProfile.fullName}
                <IconButton
                  icon={IconUndo}
                  onClick={async () => await handleEndImpersonation()}
                  label={trans('base.back_to_your_profile')}
                />
              </div>
            )}
            {currentProfile && !isImpersonating() && (
              <div className="flex gap-2">
                <Select
                  options={[
                    ...institutionOptions,
                    {value: 'divider', isDivider: true} as _SelectValue,
                    {value: 'new', label: trans('organisation.new')},
                  ]}
                  value={institutionOptions.find((opt: _SelectValue) => opt.value === currentInstitution?.id)}
                  onChange={handleInstitutionChange}
                  placeholder={trans('base.select_institution')}
                  size="sm"
                  width="relative-col-width-2"
                />
                {(can('organisation.teams:create') || teamOptions.length > 0) && (
                  <Select
                    options={[
                      ...teamOptions,
                      ...(can('organisation.teams:create')
                        ? [
                            {value: 'divider', isDivider: true} as _SelectValue,
                            {value: 'new', label: trans('organisation.create_new_team')},
                          ]
                        : []),
                    ]}
                    value={
                      teamOptions.find(opt => opt.value === currentTeam?.id) || {
                        value: 'teams',
                        label: trans('organisation.teams'),
                      }
                    }
                    onChange={handleTeamChange}
                    placeholder={trans('base.select_team')}
                    size="sm"
                    width="relative-col-width-2"
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="shrink-0 topBar-height bg-white500 flex items-center gap-2 pt-[12px] pb-[12px]">
          {currentProfile && !isImpersonating() && (
            <div className="flex items-end h-full gap-1">
              {showCoachingButton && (
                <ToolTip
                  txt={can('organisation.details:edit') ? trans('base.book_a_coaching_session_tooltip') : undefined}
                  plc="bottom"
                >
                  <Button
                    label={trans('base.book_a_coaching_session')}
                    iconPosition="left"
                    icon={IconSchedule}
                    onClick={() => goToCoachingSession(coachingButtonUrl)}
                    width="relative-col-width-2"
                    size="sm"
                  />
                </ToolTip>
              )}
              <UpgradePlanButton source="profile" width="relative-col-width-2" size="sm" />
            </div>
          )}

          <div className="flex items-end h-full">
            <FlagDropDown profileLang={currentLanguage} onLanguageChange={setLanguage} />
          </div>

          {originalProfile && !isImpersonating() && (
            <div className="flex flex-row gap-2 justify-end items-end ">
              <TopBarNotifications profile={originalProfile} />
              <TopBarUser profile={originalProfile} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
