// @ts-nocheck
/* eslint-disable */

import React, {useMemo} from 'react';
import {usePathname} from 'next/navigation';
import {loggingRoutes, userMenuRoutes} from '@/_core/routes';
import {useClickOutOfBoundsHelper} from '@/_core/util/cssHelper';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {ListItem} from '@modules/Core/components/base/inputs/ListItem';
import {_Route} from '@modules/Core/types/routesTypes';
import {useUiStore, zustandApi} from '@modules/Core/util/logic/zustand';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {_Profile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _TopBarUser {
  profile: _Profile | null;
}

const buttonId = 'user-menu-button';

type MenuItem = _Route | {isDivider: true};

export const TopBarUser: React.FC<_TopBarUser> = ({profile}) => {
  const {showUserMenu} = useUiStore();

  const handleClick = (): void => {
    zustandApi.updateUiStore({showUserMenu: !showUserMenu});
  };

  return (
    <div className="flexCenterCenter">
      <button id={buttonId} onClick={handleClick}>
        <AvatarUser image={getProfilePic(profile)} label={profile?.fullName} />
      </button>
      {showUserMenu && <UserMenu profile={profile} />}
    </div>
  );
};

// todo: click outside to close
const UserMenu: React.FC<{profile: _Profile | null}> = ({profile}) => {
  const {navigate} = useAppNavigate();
  const pathname = usePathname();

  const userItems = useMemo(
    () =>
      [
        userMenuRoutes.profile,
        userMenuRoutes.emails,
        {isDivider: true},
        userMenuRoutes.membership,
        userMenuRoutes.settings,
        {isDivider: true},
        userMenuRoutes.softfactionary,
        {isDivider: true},
        loggingRoutes.logout,
      ] as MenuItem[],
    []
  );

  const getIsActive = (value: string, to: string): boolean => {
    return pathname?.includes(to);
  };

  const handleClick = (to: string): void => {
    navigate(to);
    zustandApi.updateUiStore({showUserMenu: false});
  };

  const handleClickOutside = (): void => zustandApi.updateUiStore({showUserMenu: false});
  const ref = useClickOutOfBoundsHelper(handleClickOutside, buttonId);

  return (
    <div ref={ref} className="absolute z-40 shadow-2 right-[0px] top-[100%] bg-white500 mt-1 relative-col-width-2">
      {userItems.map((item, i) => (
        <React.Fragment key={'isDivider' in item ? `divider-${i}` : item.value}>
          {'isDivider' in item ? (
            <div style={{height: '1px', backgroundColor: 'var(--color-grey300)', margin: '0 5px'}} />
          ) : (
            <ListItem
              active={getIsActive(item.value, item.to || '')}
              label={trans(item.displayValue as string)}
              onChange={(): void => {
                if (item.to) {
                  handleClick(item.to);
                } else if (item.onClick) {
                  item.onClick({
                    profile,
                  });
                }
              }}
              icon={item.icon}
              lineClamp
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
