// @ts-nocheck
/* eslint-disable */

import {useSelectState} from '@modules/State/hooks/selectState';
import {_Team} from '@modules/Team/types/team.model';
import {currentTeam} from '../util/teamUtil';

export function useTeam(): [_Team | null] {
  const team = currentTeam();
  return [team];
}
